<template>
    <section>
        <div class="row mx-3 mt-3">
            <i class="icon-left text-general cr-pointer f-18" @click="$emit('volver')" />
            <div class="col f-18 text-general f-600">
                Asignar {{ $config.vendedor }}
            </div>
        </div>
        <div class="row mx-0 mt-5 justify-center">
            <div class="col-10 mb-3">
                <small class="pl-3 text-general">
                    Seleccionar {{ $config.vendedor }}
                </small>
                <el-select
                v-model="value"
                class="w-100"
                size="small"
                filterable
                remote
                reserve-keyword
                placeholder="Seleccionar"
                :remote-method="busquedaSelect"
                @change="setTienda"
                >
                    <el-option
                    v-for="item in selectLeecheros"
                    :key="item.id"
                    :label="item.leechero"
                    :value="item.id"
                    />
                </el-select>
            </div>
            <div v-for="(data,k) in leecheros" :key="k" class="col-10 d-middle mb-2">
                <img :src="data.imagen" class="rounded-circle border obj-cover" width="24" height="24" />
                <div class="col text-general f-14">
                    {{ data.nombre }}
                </div>
                <i class="icon-delete-trash f-18 text-general cr-pointer" @click="setTiendaEliminar(data.id)" />
            </div>
            <div v-if="$can('gestionar_asignacion_geocercas')" class="col-10 mt-3">
                <div class="btn-general" @click="asignarGeocercaTienda">
                    Añadir
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Geocerca from '~/services/geocerca/geocerca'
export default {
    props: {
        idGrupo:{
            type: Number,
            required: true
        },
    },
    data(){
        return {
            urlPersona: 'https://img.freepik.com/foto-gratis/retrato-joven-sonriente-gafas_171337-4842.jpg?w=2000',
            value: null,
            selectLeecheros: [],
            buscar: '',
            leecheros: [],
        }
    },
    mounted(){
        this.selectLeechero()
    },
    methods: {
        busquedaSelect(b){
            this.buscar = b
            this.selectLeechero()
        },
        async selectLeechero(){
            try {
                if (this.leecheros.length > 0){
                    const params = {
                        idGrupo: this.idGrupo,
                        buscar: this.buscar,
                        idTienda: _.map(this.leecheros, 'id')
                    }
                    const {data} = await Geocerca.selectLeechero(params)
                    this.selectLeecheros = data?.data

                }  else {
                    const {data} = await Geocerca.selectLeechero({buscar: this.buscar, idGrupo: this.idGrupo})
                    this.selectLeecheros = data?.data
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        setTienda(id){
            let that = this
            this.selectLeecheros.forEach(function(value){
                if (value.id == id){
                    var datos = {
                        nombre: value.leechero,
                        imagen: value.imagen_mini,
                        id: id
                    }
                    that.leecheros.push(datos)
                }
            })
            this.value = null

            if (this.leecheros.length > 0){
                let idx = this.selectLeecheros.findIndex(p=>p.id === id)
                this.selectLeecheros.splice(idx, 1);
            }
        },
        setTiendaEliminar(id){
            let idx = this.leecheros.findIndex(p=>p.id === id)
            this.leecheros.splice(idx, 1);
        },
        async asignarGeocercaTienda(){
            try {
                if(this.leecheros.length === 0){
                    this.notificacion('Alerta','Debe agregar almenos una (1) tienda','warning')
                    return
                }

                const payload = {
                    idGrupo: this.idGrupo,
                    idTienda: _.map(this.leecheros, 'id')
                }

                await Geocerca.asignarGeocercaTienda(payload)

                this.notificacion('','Grupo geocerca tienda asignado correctamente','success')
                this.$emit('volver');
                this.$emit("cargar");
            } catch (e){
                this.error_catch(e)
            }
        }
    },
}
</script>
